import React, { useEffect } from 'react'



const InstaHandle = ({}) => {

    useEffect(() => {

        const fetchData = async (fragment) => {
            try {
              // Perform your asynchronous operation here

              let otherApp = false
              let otherApp1 = false
              let fragmentStr = `${fragment}`
              if (fragmentStr.includes("-1")){
                otherApp = true

                fragmentStr = fragmentStr.replace("-1", "")
              } else if(fragmentStr?.includes("-app1")){
                otherApp1 = 'app1'
                fragmentStr = fragmentStr.replace("-app1", "")
              } else if(fragmentStr?.includes("-app2")){
                otherApp1 = 'app2'
                fragmentStr = fragmentStr.replace("-app2", "")
              } else if(fragmentStr?.includes("-app3")){
                otherApp1 = 'app3'
                fragmentStr = fragmentStr.replace("-app3", "")
              }

              const response = await fetch(otherApp1 === 'app2' ? `https://api.creatorcheckout.com/api/v1/auth/ig-creator-login?${fragmentStr}&client_id=1111435320207600&client_secret=154db4c8f276be9b808b4c7cb90c79e2` : `https://api.creatorcheckout.com/api/v1/auth/ig-creator-login?${fragmentStr}`);

              const data = await response.json();

              if(otherApp) {
                window.location.href = "https://creatorlogin.creatorcheckout.com/main/insta-handle"
              } else if(otherApp1 === 'app1') {
                window.location.href = "https://application.creatorcheckout.com/main/insta-handle"
              } else if(otherApp1 === 'app2') {
                window.location.href = "https://application2.creatorcheckout.com/main/insta-handle"
              } else if(otherApp1 === 'app3') {
                window.location.href = "https://creatorlogin.creatorcheckout.com/main/insta-handle"
              } else {
                window.location.href = "https://creatorlogin.creatorcheckout.com/main/insta-handle"
              }

            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          // Call the asynchronous function
        let fragmentCheck = window.location.href;
        let fragment = null;
        if (fragmentCheck.includes("error")){
          fragment = fragmentCheck?.split("?")[1]
        } else {

          fragment = window.location.hash.substring(1);
        }
        if (fragment)
          fetchData(fragment);
    
    
        // Make an HTTP request to the server with the fragment
        // fetch(`https://your-api.com/endpoint?fragment=${encodeURIComponent(fragment)}`)
        //     .then(response => response.json())
        //     .then(data => console.log(data))
        //     .catch(error => console.error('Error:', error));
        }, [])

    return (
        <></>
    )
}


export default InstaHandle; 