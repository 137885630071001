import React, { useEffect, useState } from 'react'
import logo from "./asset/logo/download.png"
import facebookLogo from "./asset/logo/facebook-app-symbol.png"
import { useLocation } from 'react-router-dom'


const FacebookLogin = ({}) => {

    const location = useLocation()

    const [username, setUsername] = useState("")

    useEffect(() => {
        if(location.pathname){
          let paramUser = location.pathname.split("/")[1]
        
            setUsername(paramUser)
        }
      }, [location])

    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: '20%'}}>
            <div style={{width: "30%", height: "30%", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)", padding: "20px", borderRadius: "15px", background: "white"}}>
                <div style={{display: "flex", justifyContent: "center", fontSize: "28px", fontWeight: "bold", letterSpacing: "1px", fontFamily: "Manrope"}}>
                    {/* <img src={logo} style={{height: "40px", width: "130px"}}/> */}
                    Paper<span style={{color: 'rgb(77, 99, 221)'}}>plane</span>
                </div>

                <div style={{display: "flex", justifyContent: "center", marginTop: "10%", fontFamily: "Manrope", fontSize: "22px", fontWeight: "bold"}}>
                    Login with Facebook
                </div>

                <div style={{display: "flex", justifyContent: "center", marginTop: "4%", fontFamily: "Manrope", alignItems: "center"}}>
                    <button style={{
                        display: "flex",
                        padding: "15px 30px",
                        borderRadius: "10px",
                        background: "#1877f2",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "18px",
                        border: "none",
                        cursor: "pointer"
                    }} onClick={() => {
                        const windowFeatures = 'width=1100,height=700,location=no,menubar=no,toolbar=no';

                        // Open a new window
                        if(username)
                         window.open(`https://www.facebook.com/v18.0/dialog/oauth?client_id=316919167961476&display=page&extras=%7B%22setup%22%3A%7B%22channel%22%3A%22IG_API_ONBOARDING%22%7D%7D&redirect_uri=https%3A%2F%2Fcreator.creatorcheckout.com%2Finstagram-handle&response_type=token&scope=pages_show_list%2C%20business_management%2C%20pages_messaging%2C%20instagram_basic%2C%20instagram_manage_comments%2C%20instagram_manage_insights%2C%20instagram_content_publish%2C%20instagram_manage_messages%2C%20pages_read_engagement%2C%20pages_manage_metadata%2C%20public_profile&state=${username}`, 'MiniWindow', windowFeatures);
                    
                    }}>
                        <img src={facebookLogo} style={{height: "20px", width: "20px", marginRight: "10px"}} />
                        Login
                    </button>
                </div>
            </div>
        </div>
    )
}


export default FacebookLogin; 