import { useEffect } from "react";
import { Route, BrowserRouter, Routes, Redirect } from "react-router-dom"
import InstaHandle from "./IistaHandle";
import FacebookLogin from "./facebookLogin";


function App() {


  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route exact path="/instagram-handle" Component={() => <InstaHandle />} />

        <Route path="/:username" Component={() => <FacebookLogin />} />
        <Route path="*" Component={() => <FacebookLogin />} />
      </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
